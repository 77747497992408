import { User } from 'types';

// Associate UUID with Segment anonymousId & refresh available account details
export const segmentIdentify = (currentUser: User) => {
  try {
    window.analytics.identify(currentUser.uuid, {
      username: currentUser.username!,
      firstName: currentUser.firstName!,
      lastName: currentUser.lastName!,
      email: currentUser.email!,
      phone: '1' + currentUser.phone!, // Country code "1" prefixed for compatibility with Braze
      avatar: currentUser.profileImage!,
      status: currentUser.status!,
      account_balance: currentUser.accountBalance!,
      createdAt: currentUser.createdAt!,
      badge_count: currentUser.badgeCount,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

// Trigger Segment Track event with additional properties if required
export const segmentTrack = (
  event: string,
  eventType: string = 'Page',
  eventProperties?: object,
  eventAction?: string
) => {
  try {
    let eventName: string = event;

    // Determine tracking string format based on eventType, allowing for override by action parameter
    if (eventAction) {
      eventName += eventType + ' ' + eventAction;
    } else if (eventType === 'Link' || eventType === 'Button') {
      eventName += eventType + ' clicked';
    } else if (eventType === 'Field') {
      eventName += eventType + ' selected';
    } else {
      eventName += eventType + ' viewed';
    }

    // Send event to Segment
    window.analytics.track(eventName, eventProperties);
  } catch (error) {
    console.error(error);
    return error;
  }
};

// Trigger Segment Page event with additional properties if required
export const segmentPageView = (pathname: string, eventProperties?: object) => {
  try {
    // Send Page event to Segment
    window.analytics.page(pathname, eventProperties);
  } catch (error) {
    console.error(error);
    return error;
  }
};
