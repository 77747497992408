import { gql } from '@apollo/client';
export const TRANSFER_FIELDS_FRAGMENT = gql`
  fragment transferFields on Transfer {
    uuid
    amount
    displayDateTime
    payorReaction
    payeeReaction
    status
    scope
    type
    description
    smsMessage
    remindedAt
    createdAt
    updatedAt
    debitor {
      ...userDetailFields
    }
    creditor {
      ...userDetailFields
    }
    paymentTemplate {
      ...paymentTemplateMinimalFields
    }
  }
`;
