import React, { useEffect } from 'react';
import FlinksCustomScreens from 'components/FlinksCustomScreens';
import PageContainer from 'components/PageContainer';
import PageContent from 'components/PageContent';

const LoadingPage = () => {
  useEffect(() => {
    const ifrbody = document.body;
    if (ifrbody) ifrbody.style.background = '#fff';
  }, []);

  const screen = {
    type: 'connecting',
    title: 'Connecting...',
    text: 'Verto is connecting to your bank.<br/>This will take a few minutes.',
    icon: 'clock',
    loop: true,
  };

  return (
    <PageContainer>
      <PageContent>
        <FlinksCustomScreens
          show={true}
          onHide={null}
          screen={screen}
          onAction={null}
          onSecondary={null}
          onTertiary={null}
        />
      </PageContent>
    </PageContainer>
  );
};

export default LoadingPage;
