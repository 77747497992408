import { getTestmode } from 'lib/vertoStore';
import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import InitApp from 'screens/InitApp';
import LoadingPage from 'screens/LoadingPage';

const App = () => {
  // Lazy load the different apps if required
  const CustomerApp = React.lazy(() => import('components/CustomerApp'));
  const CheckoutApp = React.lazy(() => import('components/CheckoutApp'));
  const BusinessApp = React.lazy(() => import('components/BusinessApp'));
  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Routes>
          <Route
            path="/app/init-app"
            element={<InitApp />}
          />
          <Route
            path="/app/loading"
            element={<LoadingPage />}
          />
          <Route
            path="/app/*"
            element={<CustomerApp />}
          />
          <Route
            path="/checkout/*"
            element={<CheckoutApp />}
          />
          <Route
            path="/business/*"
            element={getTestmode() ? <Navigate to="/app" /> : <BusinessApp />}
          />
          <Route
            path="*"
            element={<Navigate to="/app" />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
