import { useRef, useState, useEffect } from 'react';

const useModalOverflow = (valid = true) => {
  const modalBody = useRef<any>(null);
  const [isOverflow, setIsOverflow] = useState<boolean>(true);

  useEffect(() => {
    const { current } = modalBody;
    if (!current || !valid) return;

    function onResize() {
      setIsOverflow(current.clientHeight < current.scrollHeight);
    }

    function onScroll() {
      setIsOverflow(
        current.scrollTop + current.clientHeight < current.scrollHeight
      );
    }

    onResize();
    onScroll();
    window.addEventListener('resize', onResize);
    current.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', onScroll);
    };
  }, [modalBody]);

  return { modalBody, isOverflow };
};

export default useModalOverflow;
