import { gql } from '@apollo/client';
export const SPLIT_GROUP_FRAGMENT = gql`
  fragment splitGroupFields on SplitGroup {
    amount
    amountPaid
    createdAt
    description
    name
    status
    updatedAt
    uuid
    pendingActivities {
      ...paymentItemFields
    }
    historyActivities {
      ...paymentItemFields
      splitGroupUser {
        ...splitGroupUserFields
      }
    }
    paidOfflineActivities {
      user {
        ...userDetailFields
      }
      amount
      status
      manualAmount
      createdAt
      updatedAt
    }
    user {
      ...userDetailFields
    }
  }
`;
