import Cookies from 'universal-cookie';
import { add } from 'date-fns';
import { maskPhoneNumber } from './utils';
import { getTestmode } from 'lib/vertoStore';

// Get App Mode on the fly
const currentMode = () => (getTestmode() ? 't' : 'l');

// Btoa here gerenates a name too big to work correctly
const UuidCookieKey = () => `_verto_session_${currentMode()}aa`;
const PhoneCookieKey = () => `_verto_session_${currentMode()}cc`;
const MaskedPhoneCookieKey = () => `_verto_session_${currentMode()}bb`;

const sessionCookies = new Cookies(null, { path: '/' });
export const cookies = {
  getUuid: () => sessionCookies.get(UuidCookieKey()),
  setUuid: (value: string) => sessionCookies.set(UuidCookieKey(), btoa(value)),
  getPhone: () => {
    try {
      return atob(sessionCookies.get(PhoneCookieKey()));
    } catch (e) {}
  },
  setPhone: (value: string) =>
    sessionCookies.set(PhoneCookieKey(), btoa(value), {
      expires: add(new Date(), { years: 1 }),
    }),
  getMaskedPhone: () => sessionCookies.get(MaskedPhoneCookieKey()),
  setMaskedPhone: (value: string) =>
    sessionCookies.set(MaskedPhoneCookieKey(), maskPhoneNumber(value)),
};
