import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import '@dotlottie/player-component';
import { publicAssetPath } from 'lib/utils';
import useModalOverflow from 'hooks/useModalOverflow';
import { segmentTrack } from 'lib/Segment';

export default function FlinksCustomScreens({
  show,
  onHide,
  screen,
  onAction,
  onSecondary,
  onTertiary,
}) {
  const { modalBody, isOverflow } = useModalOverflow();
  const ScreenIcons = {
    clock: '/dot-lotties/clock.lottie',
    checkMark: '/dot-lotties/check-mark.lottie',
    help: '/dot-lotties/help.lottie',
  };

  useEffect(() => {
    switch (screen.type) {
      case 'connecting':
        segmentTrack('BankConnectionInitiated');
        break;
      case 'allSet':
        segmentTrack('BankConnectionFinished');
        break;
      case 'help':
        segmentTrack('BankConnectionHelp');
        break;

      default:
        break;
    }
  }, [screen.type]);

  return (
    <Modal
      dialogClassName="is-fullscreen-mobile"
      backdropClassName="is-fullscreen-mobile"
      centered
      scrollable
      show={show}
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title />
        {screen.type !== 'connecting' && screen.type !== 'allSet' && (
          <button
            type="button"
            className="close d-block"
            onClick={onHide}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        )}
      </Modal.Header>

      <Modal.Body
        bsPrefix="modal-body pt-0"
        ref={modalBody}
        className={` ${isOverflow ? 'is-overflow' : ''}`}
      >
        <div className="h-100 d-flex flex-column">
          {screen.type != 'manual' ? (
            <div className="text-center">
              <div className="icon icon--64 text-primary">
                {screen.loop ? (
                  <dotlottie-player
                    src={publicAssetPath(ScreenIcons[screen.icon])}
                    autoplay
                    loop
                    width={64}
                    height={64}
                  />
                ) : (
                  <dotlottie-player
                    src={publicAssetPath(ScreenIcons[screen.icon])}
                    autoplay
                    width={64}
                    height={64}
                  />
                )}
              </div>
              <h1 className="h2">{screen.title}</h1>
              <p
                className="text-sm text-muted"
                dangerouslySetInnerHTML={{ __html: screen.text }}
              />
            </div>
          ) : (
            <iframe
              src={screen.form}
              name="manually_connect"
              title="Manually Connect"
              className="iframe flinksconnect-iframe"
            ></iframe>
          )}
        </div>
      </Modal.Body>
      {screen.ctaLabel && (
        <Modal.Footer className="flex-column">
          <div className="d-flex flex-row w-100">
            {screen.ctaSecondaryLabel && (
              <div className="px-1 flex-grow-1 w-100 w-sm-50">
                <Button
                  variant="outline-light"
                  onClick={onSecondary}
                  block
                >
                  {screen.ctaSecondaryLabel}
                </Button>
              </div>
            )}
            {screen.ctaLabel && (
              <div className="px-1 flex-grow-1 w-100 w-sm-50">
                <Button
                  variant="primary"
                  onClick={onAction}
                  block
                >
                  {screen.ctaLabel}
                </Button>
              </div>
            )}
          </div>
          {screen.ctaTertiaryLabel && (
            <div className="w-100">
              <Button
                variant="link"
                onClick={onTertiary}
                block
              >
                {screen.ctaTertiaryLabel}
              </Button>
            </div>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
}
