import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  makeVar,
  defaultDataIdFromObject,
} from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { FindPeople, User, UserDetail } from 'types';
import { ME } from 'apollo/queries/me';
import { PAYMENT_ITEM_FRAGMENT } from 'apollo/fragments/paymentItem/fields';
import { CURRENT_USER_FRAGMENT } from 'apollo/fragments/user/currentUserFields';
import { SPLIT_GROUP_FRAGMENT } from 'apollo/fragments/splitGroup/fields';
import { SPLIT_GROUP_LIST_FRAGMENT } from 'apollo/fragments/splitGroup/listFields';
import { PAYMENT_METHOD_FRAGMENT } from 'apollo/fragments/paymentMethod/fields';
import { PAYMENT_TEMPLATE_FRAGMENT } from 'apollo/fragments/paymentTemplate/fields';
import { CURRENT_USER_MINIMAL_FRAGMENT } from 'apollo/fragments/user/currentUserMinimal';
import { PAYMENT_METHOD_MINIMAL_FRAGMENT } from 'apollo/fragments/paymentMethod/minimal';
import { PAYMENT_TEMPLATE_MINIMAL_FRAGMENT } from 'apollo/fragments/paymentTemplate/minimal';
import { REACTIONS } from 'apollo/fragments/transfer/reactions';
import { SPLIT_GROUP_USER_FRAGMENT } from 'apollo/fragments/splitGroupUser/fields';
import { TRANSFER_FIELDS_FRAGMENT } from 'apollo/fragments/transfer/fields';
import { USER_DETAIL_FIELDS_FRAGMENT } from 'apollo/fragments/user/details';
import { MEMBERSHIP_FRAGMENT } from 'apollo/fragments/membership/fields';
import { ORGANIZATION_FRAGMENT } from 'apollo/fragments/organization/fields';
import { getToken, isAuthed, getTestmode } from './vertoStore';

const VERTO_API_URL = process.env.REACT_APP_VERTO_API_URL;
// const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');

const httpLink = createHttpLink({
  uri: VERTO_API_URL,
  // credentials: 'same-origin'
});

const formatHeader = (headers: any, token: string) => {
  return {
    headers: {
      ...headers,
      // 'X-CSRF-Token': csrfToken,
      Authorization: token ? `Bearer ${token}` : '',
      // livemode means the url contains a test checkout session
      'x-verto-livemode': !getTestmode(),
    },
  };
};

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return formatHeader(headers, token);
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    dataIdFromObject(responseObject: any) {
      if (responseObject.__typename && responseObject.uuid) {
        return `${responseObject.__typename}:${responseObject.uuid}`;
      }
      return defaultDataIdFromObject(responseObject);
    },
    possibleTypes: {
      PaymentItem: [
        'Transfer',
        'ScheduledTask',
        'Payment',
        'PaymentRequest',
        'BankTransfer',
      ],
      Payable: ['VertoAccount', 'HoldingAccount', 'Wallet', 'PaymentMethod'],
    },
    fragments: createFragmentRegistry(
      CURRENT_USER_FRAGMENT,
      CURRENT_USER_MINIMAL_FRAGMENT,
      PAYMENT_ITEM_FRAGMENT,
      PAYMENT_METHOD_FRAGMENT,
      PAYMENT_METHOD_MINIMAL_FRAGMENT,
      PAYMENT_TEMPLATE_FRAGMENT,
      PAYMENT_TEMPLATE_MINIMAL_FRAGMENT,
      REACTIONS,
      SPLIT_GROUP_FRAGMENT,
      SPLIT_GROUP_LIST_FRAGMENT,
      SPLIT_GROUP_USER_FRAGMENT,
      TRANSFER_FIELDS_FRAGMENT,
      USER_DETAIL_FIELDS_FRAGMENT,
      MEMBERSHIP_FRAGMENT,
      ORGANIZATION_FRAGMENT
    ),
    typePolicies: {
      User: {
        fields: {
          token: {
            read() {
              return getToken();
            },
          },
        },
      },
      SplitGroup: {
        fields: {
          historyActivities: {
            merge: false,
          },
          pendingActivities: {
            merge: false,
          },
          paidOfflineActivities: {
            merge: false,
          },
        },
      },
      Query: {
        fields: {
          transferActivities: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: ['scope', 'userUuid'],

            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing, incoming, options) {
              if (!incoming) return existing;
              if (!existing) return incoming;

              // INCOMPLETE (REMOVE OR PAGINATE)
              if (
                options?.args?.scope === 'incomplete' ||
                options?.args?.scope === 'pending'
              ) {
                if (
                  existing.page === incoming.page &&
                  existing.limit === incoming.limit &&
                  incoming.activities.length < existing.activities.length
                ) {
                  return { ...existing, activities: incoming.activities };
                } else {
                  return {
                    ...existing,
                    page: incoming.page,
                    activities: [
                      ...(existing.activities || []),
                      ...incoming?.activities,
                    ].filter(
                      (v, i, a) =>
                        a.findIndex((v2) => v2.__ref === v.__ref) === i
                    ),
                  };
                }
              }

              // HISTORY PAGINATION
              const activities = [
                ...incoming.activities,
                ...existing.activities,
              ].filter(
                (v, i, a) => a.findIndex((v2) => v2.__ref === v.__ref) === i
              );
              const page =
                activities.length === incoming.totalEntries
                  ? incoming.lastPage
                  : Math.floor(activities.length / incoming.limit);
              return {
                ...incoming,
                page,
                activities,
              };
            },
          },
          paymentActivities: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: ['scope', 'userUuid'],

            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing, incoming, options) {
              if (!incoming) return existing;
              if (!existing) return incoming;

              // INCOMPLETE (REMOVE OR PAGINATE)
              if (options?.args?.scope === 'incomplete') {
                if (
                  existing.page === incoming.page &&
                  existing.limit === incoming.limit &&
                  incoming.activities.length < existing.activities.length
                ) {
                  return { ...existing, activities: incoming.activities };
                } else {
                  return {
                    ...existing,
                    page: incoming.page,
                    activities: [
                      ...(existing.activities || []),
                      ...incoming?.activities,
                    ].filter(
                      (v, i, a) =>
                        a.findIndex((v2) => v2.__ref === v.__ref) === i
                    ),
                  };
                }
              }

              // HISTORY PAGINATION
              const activities = [
                ...incoming.activities,
                ...existing.activities,
              ].filter(
                (v, i, a) => a.findIndex((v2) => v2.__ref === v.__ref) === i
              );
              const page =
                activities.length === incoming.totalEntries
                  ? incoming.lastPage
                  : Math.floor(activities.length / incoming.limit);
              return {
                ...incoming,
                page,
                activities,
              };
            },
          },
        },
      },
    },
  }),
  connectToDevTools: true,
  defaultOptions: {
    mutate: { errorPolicy: 'all' },
  },
});

export const setCurrentUser = (user) => {
  client.writeQuery({
    query: ME,
    data: { me: user },
  });
};

// Apollo client local store
export type ICurrentUser = User | null;
export const isLoggedIn = makeVar<boolean>(isAuthed());

export type IVertoPeople = Array<UserDetail> | null | undefined;

//findPeople persisted client store
// Usage: call currentFindPeople() anywhere in the system
export type IFindPeople = FindPeople | null | undefined;
export const currentFindPeople = makeVar<IFindPeople>(undefined);

export const persistServerFindPeople = (findPeopleData) => {
  localStorage.setItem(
    'VERTO_FIND_PEOPLE',
    JSON.stringify(findPeopleData!.findPeople)
  );
  currentFindPeople(findPeopleData!.findPeople);
};

export const rehidrateFindPeopleFromLocalStorage = () => {
  const persistedFindPeople = localStorage.getItem('VERTO_FIND_PEOPLE');
  const findPeople = persistedFindPeople && JSON.parse(persistedFindPeople);
  currentFindPeople(findPeople);
};
