import React from 'react';
import { Container } from 'react-bootstrap';

type Props = {
  children?: React.ReactNode;
};

const PageContent = ({ children }: Props) => {
  return (
    <div className={`page-content`}>
      <div className="page-content__inner">
        <Container>{children}</Container>
      </div>
    </div>
  );
};

export default PageContent;
