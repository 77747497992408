import { gql } from '@apollo/client';
export const PAYMENT_METHOD_MINIMAL_FRAGMENT = gql`
  fragment paymentMethodMinimalFields on PaymentMethod {
    checking
    isConfirmed
    isPreferred
    name
    paymentMethod
    status
    type
    uuid
    vendor
  }
`;
