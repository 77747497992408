const LANG_KEYS = {
  NEW_USER_WELCOME: 'new-user.wellcome',
  HOME_WELCOME: 'home.welcome',
  HOME_GOOD_MORNING: 'home.good_morning',
  HOME_GOOD_EVENING: 'home.good_evening',
  HOME_GOOD_AFTERNOON: 'home.good_afternoon',
  NONE: 'none',
  APP_PAYGETPAID: 'app.payGetPaid',
  GUEST_TITLE: 'guest.title',
  GUEST_CONTENT: 'guest.content',
  HELP_TITlE: 'help.title',
  SIGNIN_TITLE: 'signin.title',
  SIGNUP_INVITED_TITLE: 'signup.invited-title',
  SIGNUP_INVITED_COMPLETED: 'signup.invited-complete',
  CREATEPASSWORD_TITLE: 'createpassword.title',
  ACTIVITY_DETAIL_EXPIRED_REQUEST_TO: 'activity-detail.expired-request-to',
  ACTIVITY_DETAIL_EXPIRED_REQUEST_FROM: 'activity-detail.expired-request-from',
  ACTIVITY_DETAIL_EXPIRED_PAYMENT_TO: 'activity-detail.expired-payment-to',
  ACTIVITY_DETAIL_EXPIRED_PAYMENT_FROM: 'activity-detail.expired-payment-from',
  ACTIVITY_DETAIL_DECLINED_REQUEST_TO: 'activity-detail.declined-request-to',
  ACTIVITY_DETAIL_DECLINED_REQUEST_FROM:
    'activity-detail.declined-request-from',
  ACTIVITY_DETAIL_DECLINED_PAYMENT_TO: 'activity-detail.declined-payment-to',
  ACTIVITY_DETAIL_DECLINED_PAYMENT_FROM:
    'activity-detail.declined-payment-from',
  ACTIVITY_DETAIL_FAILED_REQUEST_TO: 'activity-detail.failed-request-to',
  ACTIVITY_DETAIL_FAILED_REQUEST_FROM: 'activity-detail.failed-request-from',
  ACTIVITY_DETAIL_FAILED_PAYMENT_TO: 'activity-detail.failed-payment-to',
  ACTIVITY_DETAIL_FAILED_PAYMENT_FROM: 'activity-detail.failed-payment-from',
  ACTIVITY_DETAIL_VOIDED_REQUEST_TO: 'activity-detail.voided-request-to',
  ACTIVITY_DETAIL_VOIDED_REQUEST_FROM: 'activity-detail.voided-request-from',
  ACTIVITY_DETAIL_REVOKED_PAYMENT_TO: 'activity-detail.revoked-payment-to',
  ACTIVITY_DETAIL_REVOKED_PAYMENT_FROM: 'activity-detail.revoked-payment-from',
  ACTIVITY_DETAIL_AWAITING_ACCEPTANCE: 'activity-detail.awaiting-acceptance',
  ACTIVITY_DETAIL_AWAITING_YOUR_ACCEPTANCE:
    'activity-detail.awaiting-your-acceptance',
  ACTIVITY_DETAIL_AWAITING_PAYMENT_TO: 'activity-detail.awaiting-payment-to',
  ACTIVITY_DETAIL_AWAITING_PAYMENT_FROM:
    'activity-detail.awaiting-payment-from',
  ACTIVITY_DETAIL_AWAITING_ACCEPTANCE_SHORT:
    'activity-detail.awaiting-acceptance-short',
  ACTIVITY_DETAIL_AWAITING_YOUR_ACCEPTANCE_SHORT:
    'activity-detail.awaiting-your-acceptance-short',
  ACTIVITY_DETAIL_AWAITING_MANUAL_APPROVAL:
    'activity-detail.awaiting-manual-approval',
  ACTIVITY_DETAIL_AWAITING_PAYMENT_TO_SHORT:
    'activity-detail.awaiting-payment-to-short',
  ACTIVITY_DETAIL_AWAITING_PAYMENT_FROM_SHORT:
    'activity-detail.awaiting-from-acceptance-short',
  ACTIVITY_DETAIL_PAID_TO: 'activity-detail.paid-to',
  ACTIVITY_DETAIL_RECEIVED_FROM: 'activity-detail.received-from',
  ACTIVITY_DETAIL_REQUESTED_FROM: 'activity-detail.requested-from',
  ACTIVITY_ACTION_RESULT_REMINDED: 'activity-action-result.reminded',
  ACTIVITY_ACTION_RESULT_DECLINED: 'activity-action-result.declined',
  ACTIVITY_ACTION_RESULT_CANCELLED: 'activity-action-result.cancelled',
  ACTIVITY_ACTION_RESULT_CANCELLED_END: 'activity-action-result.cancelled-end',
  ACTIVITY_NO_NEW_TRANSACTIONS: 'activity-no-new-transactions',
  ACTIVITY_NO_NEW_TRANSACTIONS_2: 'activity-no-new-transactions2',
  ACTIVITY_NO_NEW_SCHEDULED: 'activity-no-new-scheduled',
  ACTIVITY_NO_NEW_SCHEDULED_2: 'activity-no-new-scheduled2',
  TITLE_PAY_OR_REQUEST: 'title.payOrRequest',
  TITLE_PAY: 'title.pay',
  TITLE_REQUEST: 'title.request',
  TITLE_TRANSFER: 'title.transfer',
  GUEST_PAY_TITLE: 'title.payRequest',
  GUEST_PAY_CONTENT: 'title.pay.content',
  GUEST_REQUEST_TITLE: 'title.guestRequest',
  GUEST_REQUEST_CONTENT: 'title.guest.content',
  TITLE_PENDING_TRANSACTIONS: 'title.pending-transactions',
  TITLE_EDIT_PROFILE: 'title.edit-profile',
  TITLE_CHANGE_PASSWORD: 'title.change-password',
  TITLE_PAYMENTS_METHOD: 'title.payments-method',
  BUTTON_SIGNIN: 'button.signin',
  BUTTON_PAY_OR_REQUEST: 'button.payOrRequest',
  BUTTON_PAY: 'button.pay',
  BUTTON_REQUEST: 'button.request',
  BUTTON_GET_PAID: 'button.getPaid',
  BUTTON_SAVE_SETTINGS: 'button.saveSettings',
  BUTTON_CANCEL: 'button.cancel',
  BUTTON_CHANGE_PASSWORD: 'button.change-password',
  BUTTON_VERIFY: 'button.verify',
  BUTTON_CREATE_SIGNIN: 'button.createsignin',
  BUTTON_ADD_CREDIT_CARD: 'button.add-creditcard',
  BUTTON_ADD_BANK_OR_CREDIT_CARD: 'button.add-bank-or-creditcard',
  BUTTON_SHARE: 'button.share',
  FORGOTPASSWORD_TITLE: 'forgotpassword.title',
  FORGOTPASSWORD_HEADER: 'forgotpassword.header',
  FORGOTPASSWORD_INSTRUCTIONS: 'forgotpassword.instructions',
  FORGOT_PASSWORD_SMS: 'forgotpassword.sms',
  FORGOT_PASSWORD_SMSINFO: 'forgotpassword.smsinfo',
  SIGNUP_TITLE: 'signup.title',
  MENU_LOGOUT: 'menu.logout',
  MENU_HOME: 'menu.home',
  MENU_HELP: 'menu.help',
  MENU_MYACTIVITY: 'menu.myActivity',
  MENU_FINDPEOPLE: 'menu.findPeople',
  NATIVECTA_INSTALL: 'nativeCta.install',
  FORM_EMAIL: 'form.email',
  FORM_PASSWORD: 'form.password',
  FORM_NEW_PASSWORD: 'form.new-password',
  FORM_CURRENT_PASSWORD: 'form.current-password',
  FORM_RETYPE_NEW_PASSWORD: 'form.confirm-new-password',
  FORM_NAME: 'form.name',
  FORM_FIRSTNAME: 'form.firstName',
  FORM_LASTNAME: 'form.lastName',
  FORM_USERNAME: 'form.username',
  FORM_PHONE: 'form.phone',
  FORM_MOBILE_PHONE: 'form.mobilePhone',
  BALANCE_TITLE: 'balance.title',
  HEADERMENU_VIEW_PENDING_TRANSACTIONS: 'header-menu.view-pending-transactions',
  HEADERMENU_TRANSFER_BALANCE: 'header-menu.transfer-balance',
  HEADERMENU_ACTIVITY: 'header-menu.activity',
  HEADERMENU_BILL_SPLIT: 'header-menu.bill-split',
  HEADERMENU_PAYMENT_LINKS: 'header-menu.payment-links',
  HEADERMENU_SCHEDULED: 'header-menu.scheduled',
  HEADERMENU_WALLET: 'header-menu.wallet',
  HEADERMENU_FIND_PEOPLE: 'header-menu.find-people',
  HEADERMENU_NOTIFICATIONS: 'header-menu.notifications',
  HEADERMENU_TRANSFER_TO_BANK: 'header-menu.transfer-to-bank',
  HEADERMENU_SETTINGS: 'header-menu.settings',
  HEADERMENU_EDIT_PROFILE: 'header-menu.edit-profile',
  HEADERMENU_PAYMENTS_METHODS: 'header-menu.payment-methods',
  HEADERMENU_CHANGE_PASSWORD: 'header-menu.change-password',
  SIGNUP_FIRSTNAME_LABEL: 'signup.firstname-label',
  SIGNUP_LASTNAME_LABEL: 'signup.lastname-label',
  SIGNUP_USERNAME_LABEL: 'signup.username-label',
  SIGNUP_EMAIL_LABEL: 'signup.email-label',
  SIGNUP_EMAIL_FORMAT: 'signup.email-format',
  SIGNUP_PHONE_LABEL: 'signup.phone-label',
  SIGNUP_PHONE_FORMAT: 'signup.phone-format',
  SIGNUP_SECURE_PASSWORD_LABEL: 'signup.secure-password-label',
  SIGNUP_PASSWORD_LABEL: 'signup.password-label',
  SIGNUP_PASSWORD2_LABEL: 'signup.password2-label',
  SIGNUP_CONSENT_LABEL: 'signup.consent-label',
  SIGNUP_TOS_LABEL: 'signup.tos-label',
  SIGNUP_FIRSTNAME_REQUIRED: 'signup.firstname-required',
  SIGNUP_LASTNAME_REQUIRED: 'signup.lastname-required',
  SIGNUP_USERNAME_REQUIRED: 'signup.username-required',
  SIGNUP_EMAIL_REQUIRED: 'signup.email-required',
  SIGNUP_PHONE_REQUIRED: 'signup.phone-required',
  SIGNUP_USERNAME_IN_USE: 'signup.username-in-use',
  SIGNUP_EMAIL_IN_USE: 'signup.email-in-use',
  SIGNUP_PHONE_IN_USE: 'signup.phone-in-use',
  SIGNUP_PASSWORD_REQUIRED: 'signup.password-required',
  SIGNUP_PASSWORD_MATCH: 'signup.password-match',
  SIGNUP_CONFIRMATION_PASSWORD_MATCH: 'signup.confirmation-password-match',
  SIGNUP_CURRENT_PASSWORD_REQUIRED: 'signup.current-password-required',
  SIGNUP_PASSWORD_FORMAT: 'signup.password-format',
  SIGNUP_TOS_REQUIRED: 'signup.tos-required',
  SIGNUP_TOKEN_REQUIRED: 'signup.token-required',
  SIGNUP_TOKEN_FORMAT: 'signup.token-invalid',
  SIGNUP_TOKEN_VERIFIED: 'signup.token-verified',
  SIGNUP_TOKEN_SENT: 'signup.token-sent',
  SIGNUP_TEXT: 'signup.text',
  SIGNUP_BUTTON: 'signup.button',
  NEW_SIGNUP_REQUIREMENTS: 'new-signup.requirements',
  NEW_SIGNUP_CANNOT_BLANK: 'new-signup.cannot-blank',
  NEW_SIGNUP_SET_USERNAME: 'new-signup.set-username',
  NEW_SIGNUP_CONTINUE: 'new-signup.continue',
  NEW_SIGNUP_CONTINUE_4: 'new-signup.continue-4',
  NEW_SIGNUP_CONTINUE_5: 'new-signup.continue-5',
  NEW_SIGNUP_NEXT_DISABLE_1: 'new-signup.next-disable-1',
  NEW_SIGNUP_NEXT_DISABLE_2: 'new-signup.next-disable-2',
  NEW_SIGNUP_NEXT_DISABLE_3: 'new-signup.next-disable-3',
  NEW_SIGNUP_NEXT_DISABLE_4: 'new-signup.next-disable-4',
  NEW_SIGNUP_NEXT_DISABLE_5: 'new-signup.next-disable-5',
  NEW_SIGNUP_USERNAME_MIN: 'new-signup.username-min',
  NEW_SIGNUP_USERNAME_UNIQUE: 'new-signup.username-unique',
  NEW_SIGNUP_SET_PASSWORD: 'new-signup.set-password',
  NEW_SIGNUP_SET_PHONE: 'new-signup.set-phone',
  NEW_SIGNUP_SUGGEST_PASSWORD: 'new-signup.suggest-password',
  NEW_SIGNUP_PASSWORD_MIN: 'new-signup.password-min',
  NEW_SIGNUP_PASSWORD_UPCASE: 'new-signup.password-upcase',
  NEW_SIGNUP_PASSWORD_DOWNCASE: 'new-signup.password-downcase',
  NEW_SIGNUP_PASSWORD_NUMBERS: 'new-signup.password-numbers',
  NEW_SIGNUP_PHONE_IN_USE_LINE1: 'new-signup.phone-in-use-line1',
  NEW_SIGNUP_PHONE_IN_USE_LINE2: 'new-signup.phone-in-use-line2',
  NEW_SIGNUP_PHONE_MIN: 'new-signup.phone-min',
  NEW_SIGNUP_PHONE_VALID: 'new-signup.phone-valid',
  NEW_SIGNUP_EMAIL_VALID: 'new-signup.email-valid',
  NEW_SIGNUP_SEND_CODE: 'new-signup.send-code',
  NEW_SIGNUP_AGREEMENT: 'new-signup.aggreement',
  NEW_SIGNUP_INVITED_AGREEMENT: 'new-signup-invited.aggreement',
  ERROR_SOMETHING_WRONG: 'error.something-wrong',
  TRANSFER_BALANCE_ERROR: 'bank-transfer.error',
  REGISTER_SUCCESS: 'register.success',
  LABEL_ENTER_CODE: 'label.enter-code',
  FORGOT_PASSWORD_EMAIL: 'forgot-password.email',
  RESET_PASSWORD: 'reset-password.title',
  RESET_PASSWORD_MEET_REQUIREMENTS: 'reset-password.disable-button',
  RESET_PASSWORD_CONTINUE: 'reset-password.enable-button',
  RESET_PASSWORD_MESSAGE: 'reset-password.message',
  FINDPEOPLE_ADDRESS_BOOK_CONNECTED_TITLE:
    'findpeople.address-book-connected-title',
  FINDPEOPLE_CONTACTS_NUMBER: 'findpeople.contact-numbers',
  FINDPEOPLE_SEARCH_BY: 'findpeople.search-by',
  FINDPEOPLE_TO_SEARCH_CONTACTS: 'findpeople.to-search-contacts',
  FINDPEOPLE_CONNECT_ADDRESS_BOOK: 'findpeople.connect-address-book',
  PAYFORM_AMOUNT_LABEL: 'payform.amount',
  PAYFORM_NOTE_LABEL: 'payform.note',
  CLEAN_PAYFORM_NOTE_LABEL: 'payform.clean-note',
  PAYLINK_AMOUNT_OPTIONAL_LABEL: 'paylink.optional-amount',
  PAYLINK_NOTE_LABEL: 'paylink.note',
  NEW_SIGNUP_TOKEN_CONFIRMATION: 'new-signup.token-confirmation',
  NEW_SIGNUP_TOKEN_ERROR_MESSAGE: 'new-signup.token-error-message',
  NEW_SIGNUP_RESEND_TOKEN: 'new-signup.resend-token',
  NEW_SIGNUP_TOKEN_DIFFERENT_PHONE: 'new-signup.different-phone',
  NEW_SIGNUP_USERNAME_BUTTON_DISABLED: 'new-signup.username-button-disabled',
  NEW_SIGNUP_PASSWORD_BUTTON_ENABLED: 'new-signup.password-button-enabled',
  NEW_SIGNUP_PASSWORD_BUTTON_DISABLED: 'new-signup.password-button-disabled',
  NEW_SIGNUP_TERMS: 'new-signup.terms',
  GETSTARTED_TITLE: 'getstarted.title',
  REGISTER_TITLE: 'register.title',
  PHONE_TITLE: 'phone.title',
  PROFILE_MODAL_TITLE_NAME: 'profile.modal.title.name',
  PROFILE_MODAL_TITLE_VERTOTAG: 'profile.modal.title.username',
  PROFILE_MODAL_TITLE_EMAIL: 'profile.modal.title.email',
  PROFILE_MODAL_TITLE_PHONE: 'profile.modal.title.phone',
  PROFILE_MODAL_TITLE_VERIFY: 'profile.modal.title.verify',
  PROFILE_MODAL_UPDATED_NAME: 'profile.modal.updated.name',
  PROFILE_MODAL_UPDATED_USERNAME: 'profile.modal.updated.username',
  PROFILE_MODAL_UPDATED_EMAIL: 'profile.modal.updated.email',
  PROFILE_MODAL_UPDATED_EMAIL_VERIFIED: 'profile.modal.updated.email.verified',
  PROFILE_MODAL_UPDATED_PHONE: 'profile.modal.updated.phone',
  PROFILE_MODAL_UPDATED_VERIFY: 'profile.modal.updated.verify',
  PROFILE_SAVE_BUTTON_VERIFY: 'profile.modal.save.button.verify',
  PROFILE_PHONE_CHANGE_TAKE_EFFECT: 'profile.modal.phone.take.effect',
  PROFILE_EMAIL_CHANGE_TAKE_EFFECT: 'profile.modal.email.take.effect',
  PROFILE_SAVE_CHANGES: 'profile.save.changes',
  PROFILE_SEND_VERIFICATION_EMAIL: 'profile.send.verification.email',
  FORM_ENTER_EMAIL_ADDRESS: 'enter.email.address',
  ERROR_MESSAGE_01: 'error.message01',
  ERROR_MESSAGE_02: 'error.message02',
  QR_CODE_PAY_OR_REQUEST: 'qrcode.modal.payOrRequest',
  SCAN_CODE: 'menu.scan_code',
  SENT_FROM_VERTO: 'details.modal.sent.from.verto',
  SENT_TO_VERTO: 'details.modal.sent.to.verto',
  SENT_FROM_VERTO_SNOOZED: 'details.modal.sent.from.verto.snoozed',
  SENT_TO_VERTO_SNOOZED: 'details.modal.sent.to.verto.snoozed',
  ESTIMATED_ARRIVAL: 'details.modal.estimated.arrival',
  BILLSPLIT_MARK_AS_PAID: 'billsplit.mark.as.paid',
  BILLSPLIT_MARKED_AS_PAID: 'billsplit.marked.as.paid',
  PERMISSION_TITLE_FACE_ID: 'permission.title_face_id',
  PERMISSION_TEXT_FACE_ID: 'permission.text_face_id',
  PERMISSION_TITLE_REMINDER_FACE_ID: 'permission.title_reminder_face_id',
  PERMISSION_TEXT_REMINDER_FACE_ID: 'permission.text_reminder_face_id',
  PERMISSION_TITLE_CONTACTS: 'permission.title_contacts',
  PERMISSION_TEXT_CONTACTS: 'permission.text_contacts',
  PERMISSION_TITLE_REMINDER_CONTACTS: 'permission.title_reminder_contacts',
  PERMISSION_TEXT_REMINDER_CONTACTS: 'permission.text_reminder_contacts',
  PERMISSION_TITLE_CAMERA: 'permission.title_camera',
  PERMISSION_TEXT_CAMERA: 'permission.text_camera',
  PERMISSION_TITLE_REMINDER_CAMERA: 'permission.title_reminder_camera',
  PERMISSION_TEXT_REMINDER_CAMERA: 'permission.text_reminder_camera',
  PERMISSION_TITLE_GALLERY: 'permission.title_gallery',
  PERMISSION_TEXT_GALLERY: 'permission.text_gallery',
  PERMISSION_TITLE_REMINDER_GALLERY: 'permission.title_reminder_gallery',
  PERMISSION_TEXT_REMINDER_GALLERY: 'permission.text_reminder_gallery',
  PERMISSION_TITLE_NOTIFICATIONS: 'permission.title_notifications',
  PERMISSION_TEXT_NOTIFICATIONS: 'permission.text_notifications',
  PERMISSION_TITLE_REMINDER_NOTIFICATIONS:
    'permission.title_reminder_notifications',
  PERMISSION_TEXT_REMINDER_NOTIFICATIONS:
    'permission.text_reminder_notifications',
  ALERT_PREVIEW_ONLY: 'alert.preview.only',
  SCHEDULE_PAGE_TITLE: 'schedule.page.title',
  ERROR_BILLSPLIT_INVALID_PHONE: 'error.billsplit.invalid.phone',
  ACTIVITY_EXPIRED: 'activity.expired',
  ACTIVITY_EXPIRES: 'activity.expires',
  ACTIVITY_TIME_FROM_ERROR_TRANSFERPAYMENT: 'activity_time_from_error_payment',
  ACTIVITY_TIME_FROM_CANCELLED_TRANSFERPAYMENT:
    'activity_time_from_cancelled_payment',
  ACTIVITY_TIME_FROM_EXPIRED_TRANSFERPAYMENT:
    'activity_time_from_expired_payment',
  ACTIVITY_TIME_FROM_DECLINED_TRANSFERPAYMENT:
    'activity_time_from_declined_payment',
  ACTIVITY_TIME_FROM_DECLINED_TRANSFERREQUEST:
    'activity_time_from_declined_request',
  ACTIVITY_TIME_FROM_CANCELLED_TRANSFERREQUEST:
    'activity_time_from_cancelled_request',
  ACTIVITY_TIME_FROM_FAILED_TRANSFERREQUEST:
    'activity_time_from_failed_request',
  ACTIVITY_TIME_FROM_EXPIRED_TRANSFERREQUEST:
    'activity_time_from_expired_request',
  ACTIVITY_TIME_FROM_PENDING_TRANSFERREQUEST:
    'activity_time_from_pending_request',
  ACTIVITY_TIME_FROM_FAILED_TRANSFERIN: 'activity_time_from_failed_transferin',
  ACTIVITY_TIME_FROM_DECLINED_TRANSFERIN:
    'activity_time_from_declined_transferin',
  ACTIVITY_TIME_FROM_SNOOZED_DECLINED_TRANSFERIN:
    'activity_time_from_snoozed_declined_transferin',
  ACTIVITY_TIME_FROM_PENDING_TRANSFERIN:
    'activity_time_from_pending_transferin',
  ACTIVITY_TIME_FROM_QUEUED_TRANSFERIN: 'activity_time_from_queued_transferin',
  ACTIVITY_TIME_FROM_PROCESSING_TRANSFERIN:
    'activity_time_from_processing_transferin',
  ACTIVITY_TIME_FROM_FAILED_TRANSFEROUT:
    'activity_time_from_failed_transferout',
  ACTIVITY_TIME_FROM_DECLINED_TRANSFEROUT:
    'activity_time_from_declined_transferout',
  ACTIVITY_TIME_FROM_SNOOZED_DECLINED_TRANSFEROUT:
    'activity_time_from_snoozed_declined_transferout',
  ACTIVITY_TIME_FROM_PENDING_TRANSFEROUT:
    'activity_time_from_pending_transferout',
  ACTIVITY_TIME_FROM_QUEUED_TRANSFEROUT:
    'activity_time_from_queued_transferout',
  ACTIVITY_TIME_FROM_PROCESSING_TRANSFEROUT:
    'activity_time_from_processing_transferout',
};

const DEFAULT_MESSAGES: any = {
  [LANG_KEYS.NEW_USER_WELCOME]: 'Successfully signed up!',
  [LANG_KEYS.HOME_WELCOME]: 'Welcome to Verto',
  [LANG_KEYS.HOME_GOOD_MORNING]: 'Good Morning',
  [LANG_KEYS.HOME_GOOD_EVENING]: 'Good Evening',
  [LANG_KEYS.HOME_GOOD_AFTERNOON]: 'Good Afternoon',
  [LANG_KEYS.NONE]: '',
  [LANG_KEYS.ACTIVITY_DETAIL_EXPIRED_REQUEST_TO]: 'Expired Request to ',
  [LANG_KEYS.ACTIVITY_DETAIL_EXPIRED_REQUEST_FROM]: 'Expired Request from',
  [LANG_KEYS.ACTIVITY_DETAIL_EXPIRED_PAYMENT_TO]: 'Expired Payment to ',
  [LANG_KEYS.ACTIVITY_DETAIL_EXPIRED_PAYMENT_FROM]: 'Expired Payment from',
  [LANG_KEYS.ACTIVITY_DETAIL_DECLINED_REQUEST_TO]: 'Declined Request to',
  [LANG_KEYS.ACTIVITY_DETAIL_DECLINED_REQUEST_FROM]: 'Declined Request from',
  [LANG_KEYS.ACTIVITY_DETAIL_DECLINED_PAYMENT_TO]: 'Declined Payment to',
  [LANG_KEYS.ACTIVITY_DETAIL_DECLINED_PAYMENT_FROM]: 'Declined Payment from',
  [LANG_KEYS.ACTIVITY_DETAIL_FAILED_REQUEST_TO]: 'Failed Request to ',
  [LANG_KEYS.ACTIVITY_DETAIL_FAILED_REQUEST_FROM]: 'Failed Request from ',
  [LANG_KEYS.ACTIVITY_DETAIL_FAILED_PAYMENT_TO]: 'Failed Payment to',
  [LANG_KEYS.ACTIVITY_DETAIL_FAILED_PAYMENT_FROM]: 'Failed Payment from ',
  [LANG_KEYS.ACTIVITY_DETAIL_VOIDED_REQUEST_TO]: 'Revoked Request to',
  [LANG_KEYS.ACTIVITY_DETAIL_VOIDED_REQUEST_FROM]: 'Revoked Request from',
  [LANG_KEYS.ACTIVITY_DETAIL_REVOKED_PAYMENT_TO]: 'Revoked Payment to  ',
  [LANG_KEYS.ACTIVITY_DETAIL_REVOKED_PAYMENT_FROM]: 'Revoked Payment from ',
  [LANG_KEYS.ACTIVITY_DETAIL_AWAITING_ACCEPTANCE]: 'awaiting acceptance from',
  [LANG_KEYS.ACTIVITY_DETAIL_AWAITING_YOUR_ACCEPTANCE]:
    'awaiting your acceptance, paid by',
  [LANG_KEYS.ACTIVITY_DETAIL_AWAITING_PAYMENT_TO]: 'awaiting payment to',
  [LANG_KEYS.ACTIVITY_DETAIL_AWAITING_PAYMENT_FROM]: 'awaiting payment from',
  [LANG_KEYS.ACTIVITY_DETAIL_AWAITING_ACCEPTANCE_SHORT]:
    'Awaiting Their Acceptance',
  [LANG_KEYS.ACTIVITY_DETAIL_AWAITING_YOUR_ACCEPTANCE_SHORT]:
    'Awaiting Your Acceptance',
  [LANG_KEYS.ACTIVITY_DETAIL_AWAITING_MANUAL_APPROVAL]: 'Awaiting Approval',
  [LANG_KEYS.ACTIVITY_DETAIL_AWAITING_PAYMENT_TO_SHORT]:
    'Awaiting Your Payment',
  [LANG_KEYS.ACTIVITY_DETAIL_AWAITING_PAYMENT_FROM_SHORT]:
    'Awaiting Their Payment',
  [LANG_KEYS.ACTIVITY_DETAIL_PAID_TO]: 'paid to',
  [LANG_KEYS.ACTIVITY_DETAIL_RECEIVED_FROM]: 'received from',
  [LANG_KEYS.ACTIVITY_DETAIL_REQUESTED_FROM]: 'requested from',
  [LANG_KEYS.ACTIVITY_ACTION_RESULT_REMINDED]: 'is reminded',
  [LANG_KEYS.ACTIVITY_ACTION_RESULT_DECLINED]: 'You declined payment to',
  [LANG_KEYS.ACTIVITY_ACTION_RESULT_CANCELLED]: 'Your request to',
  [LANG_KEYS.ACTIVITY_ACTION_RESULT_CANCELLED_END]: 'is cancelled',
  [LANG_KEYS.ACTIVITY_NO_NEW_TRANSACTIONS]:
    "There's no transactional activity to show you here, not just yet.",
  [LANG_KEYS.ACTIVITY_NO_NEW_TRANSACTIONS_2]:
    'Send out your first payment or request to someone.',
  [LANG_KEYS.ACTIVITY_NO_NEW_SCHEDULED]:
    "There's no scheduled transactions to show you here, not just yet.",
  [LANG_KEYS.ACTIVITY_NO_NEW_SCHEDULED_2]:
    'Send out your first scheduled payment or request to someone.',
  [LANG_KEYS.GUEST_TITLE]:
    'The simplest way to send and receive money in Canada.',
  [LANG_KEYS.GUEST_CONTENT]:
    'Transfer cash, request payments, settle up, and split the bill in seconds.',
  [LANG_KEYS.GUEST_PAY_TITLE]: 'Pay',
  [LANG_KEYS.GUEST_PAY_CONTENT]:
    'Use Verto for fast, easy, and securely encrypted instant e-Transfers in Canada. Learn more at {link}',
  [LANG_KEYS.GUEST_REQUEST_TITLE]: 'Request money from someone you know.',
  [LANG_KEYS.GUEST_REQUEST_CONTENT]: 'Make your first transaction now.',
  [LANG_KEYS.HELP_TITlE]: 'Help',
  [LANG_KEYS.SIGNIN_TITLE]: 'Sign In',
  [LANG_KEYS.TITLE_PAY_OR_REQUEST]: 'Pay or Request',
  [LANG_KEYS.TITLE_PAY]: 'Pay',
  [LANG_KEYS.TITLE_REQUEST]: 'Request',
  [LANG_KEYS.TITLE_TRANSFER]: 'Transfer Funds',
  [LANG_KEYS.CREATEPASSWORD_TITLE]: 'Create Password',
  [LANG_KEYS.TITLE_PENDING_TRANSACTIONS]: 'Pending Transactions',
  [LANG_KEYS.TITLE_EDIT_PROFILE]: 'Edit Profile',
  [LANG_KEYS.TITLE_CHANGE_PASSWORD]: 'Change Password',
  [LANG_KEYS.TITLE_PAYMENTS_METHOD]: 'Payment Methods',
  [LANG_KEYS.BUTTON_SIGNIN]: 'Sign In',
  [LANG_KEYS.BUTTON_PAY_OR_REQUEST]: 'Pay or Request',
  [LANG_KEYS.BUTTON_PAY]: 'Pay',
  [LANG_KEYS.BUTTON_REQUEST]: 'Request',
  [LANG_KEYS.BUTTON_GET_PAID]: 'Request',
  [LANG_KEYS.BUTTON_SAVE_SETTINGS]: 'Save Settings',
  [LANG_KEYS.BUTTON_CANCEL]: 'Cancel',
  [LANG_KEYS.BUTTON_CHANGE_PASSWORD]: 'Change Password',
  [LANG_KEYS.BUTTON_VERIFY]: 'Verify',
  [LANG_KEYS.BUTTON_CREATE_SIGNIN]: 'Create and Sign In',
  [LANG_KEYS.BUTTON_ADD_CREDIT_CARD]: 'Add Payment Method',
  [LANG_KEYS.BUTTON_ADD_BANK_OR_CREDIT_CARD]: 'Add Bank',
  [LANG_KEYS.BUTTON_SHARE]: 'Share',
  [LANG_KEYS.FORGOTPASSWORD_TITLE]: 'Forgot Password',
  [LANG_KEYS.FORGOTPASSWORD_HEADER]: 'Forgot Password?',
  [LANG_KEYS.FORGOTPASSWORD_INSTRUCTIONS]:
    "No worries! Enter your mobile number, and we'll send you a password reset link.",
  [LANG_KEYS.SIGNUP_BUTTON]: 'Sign Up Now',
  [LANG_KEYS.SIGNUP_TITLE]: 'Sign up',
  [LANG_KEYS.SIGNUP_INVITED_TITLE]: 'Complete your Registration',
  [LANG_KEYS.SIGNUP_INVITED_COMPLETED]: 'Registration completed',
  [LANG_KEYS.MENU_LOGOUT]: 'Log Out',
  [LANG_KEYS.MENU_HOME]: 'Home',
  [LANG_KEYS.MENU_HELP]: 'Help',
  [LANG_KEYS.MENU_MYACTIVITY]: 'Activity',
  [LANG_KEYS.HEADERMENU_FIND_PEOPLE]: 'Find People',
  [LANG_KEYS.NATIVECTA_INSTALL]: 'Install our mobile app',
  [LANG_KEYS.FORM_EMAIL]: 'Email',
  [LANG_KEYS.FORM_PASSWORD]: 'Password',
  [LANG_KEYS.FORM_NEW_PASSWORD]: 'New Password',
  [LANG_KEYS.FORM_CURRENT_PASSWORD]: 'Current Password',
  [LANG_KEYS.FORM_RETYPE_NEW_PASSWORD]: 'Retype New Password',
  [LANG_KEYS.FORM_NAME]: 'Name',
  [LANG_KEYS.FORM_FIRSTNAME]: 'First name',
  [LANG_KEYS.FORM_LASTNAME]: 'Last name',
  [LANG_KEYS.FORM_USERNAME]: '$VertoTag',
  [LANG_KEYS.FORM_PHONE]: 'Phone',
  [LANG_KEYS.FORM_MOBILE_PHONE]: 'Mobile Number',
  [LANG_KEYS.BALANCE_TITLE]: 'Verto Balance',
  [LANG_KEYS.HEADERMENU_VIEW_PENDING_TRANSACTIONS]: 'View Pending Transactions',
  [LANG_KEYS.HEADERMENU_TRANSFER_BALANCE]: 'Transfer to Bank',
  [LANG_KEYS.HEADERMENU_ACTIVITY]: 'Activity',
  [LANG_KEYS.HEADERMENU_BILL_SPLIT]: 'Split Bill',
  [LANG_KEYS.HEADERMENU_PAYMENT_LINKS]: 'Create Payment Link',
  [LANG_KEYS.HEADERMENU_SCHEDULED]: 'Scheduled',
  [LANG_KEYS.HEADERMENU_WALLET]: 'My Wallet',
  [LANG_KEYS.HEADERMENU_FIND_PEOPLE]: 'Find People',
  [LANG_KEYS.HEADERMENU_NOTIFICATIONS]: 'Notifications',
  [LANG_KEYS.HEADERMENU_TRANSFER_TO_BANK]: 'Transfer to Bank',
  [LANG_KEYS.HEADERMENU_SETTINGS]: 'Settings',
  [LANG_KEYS.HEADERMENU_EDIT_PROFILE]: 'Profile',
  [LANG_KEYS.HEADERMENU_PAYMENTS_METHODS]: 'Payment Methods',
  [LANG_KEYS.HEADERMENU_CHANGE_PASSWORD]: 'Change Password',
  [LANG_KEYS.SIGNUP_FIRSTNAME_LABEL]: 'First Name',
  [LANG_KEYS.SIGNUP_LASTNAME_LABEL]: 'Last Name',
  [LANG_KEYS.SIGNUP_USERNAME_LABEL]: '$VertoTag',
  [LANG_KEYS.SIGNUP_EMAIL_LABEL]: 'Email',
  [LANG_KEYS.SIGNUP_PHONE_LABEL]: 'Mobile Phone',
  [LANG_KEYS.SIGNUP_SECURE_PASSWORD_LABEL]: 'Secure Password',
  [LANG_KEYS.SIGNUP_PASSWORD_LABEL]: 'Password',
  [LANG_KEYS.SIGNUP_PASSWORD2_LABEL]: 'Re-enter Password',
  [LANG_KEYS.SIGNUP_CONSENT_LABEL]:
    "I agree to the Consent to Receive Electronic Disclosures and understand that we'll send account notices to the email address you provided.",
  [LANG_KEYS.SIGNUP_TOS_LABEL]:
    'I have read and agree to Verto’s User Agreement and Privacy Policy.',
  [LANG_KEYS.SIGNUP_FIRSTNAME_REQUIRED]: 'First Name is required',
  [LANG_KEYS.SIGNUP_LASTNAME_REQUIRED]: 'Last Name is required',
  [LANG_KEYS.SIGNUP_USERNAME_REQUIRED]: 'Username is required',
  [LANG_KEYS.SIGNUP_EMAIL_REQUIRED]: 'Email is required',
  [LANG_KEYS.SIGNUP_EMAIL_FORMAT]: 'Email invalid',
  [LANG_KEYS.SIGNUP_PHONE_REQUIRED]: 'Mobile Phone is required',
  [LANG_KEYS.SIGNUP_USERNAME_IN_USE]: 'Username is already in use',
  [LANG_KEYS.SIGNUP_EMAIL_IN_USE]: 'Email is already in use',
  [LANG_KEYS.SIGNUP_PHONE_IN_USE]: 'Mobile Phone is already in use',
  [LANG_KEYS.SIGNUP_PHONE_FORMAT]:
    'Mobile Phone format is invalid please use (234)567-8900 format',
  [LANG_KEYS.SIGNUP_PASSWORD_REQUIRED]: 'Password is required',
  [LANG_KEYS.SIGNUP_PASSWORD_MATCH]: 'Passwords must match',
  [LANG_KEYS.SIGNUP_CONFIRMATION_PASSWORD_MATCH]: 'Passwords must match',
  [LANG_KEYS.SIGNUP_CURRENT_PASSWORD_REQUIRED]: 'Current Password is required',
  [LANG_KEYS.SIGNUP_PASSWORD_FORMAT]:
    'Must Contain 8 Characters, One Uppercase, One Lowercase, and One Number',
  [LANG_KEYS.SIGNUP_TOS_REQUIRED]:
    'Terms of Service agreement must be accepted',
  [LANG_KEYS.SIGNUP_TEXT]:
    'By clicking this button and submitting this form, you have read, understand and agree with our {link1} agreements and {link2}.',
  [LANG_KEYS.SIGNUP_TOKEN_REQUIRED]: 'Phone validation token is required',
  [LANG_KEYS.SIGNUP_TOKEN_FORMAT]: 'Phone validation token is invalid',
  [LANG_KEYS.SIGNUP_TOKEN_VERIFIED]: 'Phone Verified',
  [LANG_KEYS.SIGNUP_TOKEN_SENT]: 'Verification Code sent!',
  [LANG_KEYS.NEW_SIGNUP_REQUIREMENTS]: 'Requirements',
  [LANG_KEYS.NEW_SIGNUP_CANNOT_BLANK]: 'Fields can not be empty',
  [LANG_KEYS.NEW_SIGNUP_NEXT_DISABLE_1]: 'Enter Name',
  [LANG_KEYS.NEW_SIGNUP_NEXT_DISABLE_2]: 'Choose Username',
  [LANG_KEYS.NEW_SIGNUP_NEXT_DISABLE_3]: 'Choose Password',
  [LANG_KEYS.NEW_SIGNUP_NEXT_DISABLE_4]: 'Verify Your Mobile Number',
  [LANG_KEYS.NEW_SIGNUP_NEXT_DISABLE_5]: 'Complete Sign Up',
  [LANG_KEYS.NEW_SIGNUP_CONTINUE]: 'Continue',
  [LANG_KEYS.NEW_SIGNUP_CONTINUE_4]: 'Verify Phone Number',
  [LANG_KEYS.NEW_SIGNUP_CONTINUE_5]: 'Start Using Verto',
  [LANG_KEYS.NEW_SIGNUP_USERNAME_MIN]: 'At least 4 characters',
  [LANG_KEYS.NEW_SIGNUP_USERNAME_UNIQUE]: 'Unique, not taken',
  [LANG_KEYS.NEW_SIGNUP_SUGGEST_PASSWORD]: 'Suggest secure password',
  [LANG_KEYS.NEW_SIGNUP_PASSWORD_MIN]: 'At least 8 characters',
  [LANG_KEYS.NEW_SIGNUP_PASSWORD_UPCASE]: '1 uppercase',
  [LANG_KEYS.NEW_SIGNUP_PASSWORD_DOWNCASE]: '1 lowercase',
  [LANG_KEYS.NEW_SIGNUP_PASSWORD_NUMBERS]: '1 number',
  [LANG_KEYS.NEW_SIGNUP_PHONE_IN_USE_LINE1]:
    'This mobile number is already in use.',
  [LANG_KEYS.NEW_SIGNUP_PHONE_IN_USE_LINE2]: 'Please {{link_message}}',
  [LANG_KEYS.NEW_SIGNUP_PHONE_MIN]: '10 digits',
  [LANG_KEYS.NEW_SIGNUP_PHONE_VALID]: 'Valid mobile number',
  [LANG_KEYS.NEW_SIGNUP_EMAIL_VALID]: 'Valid email address',
  [LANG_KEYS.NEW_SIGNUP_SEND_CODE]: 'Send Code',
  [LANG_KEYS.NEW_SIGNUP_AGREEMENT]:
    'By {submit_label}, you confirm that you are authorized to use this number and agree to receive SMS texts. Carrier fees may apply.',
  [LANG_KEYS.NEW_SIGNUP_INVITED_AGREEMENT]:
    'By {submit_label}, you confirm that you are authorized to use the provided number and agree to receive SMS texts to verify you own the number. Carrier fees may apply.',
  [LANG_KEYS.REGISTER_SUCCESS]: 'Thanks for registering! Please sign in.',
  [LANG_KEYS.ERROR_SOMETHING_WRONG]:
    "Uh-oh! Looks like a minor error occurred. Our team's working on fixing it right away.",
  [LANG_KEYS.TRANSFER_BALANCE_ERROR]:
    'There was an error transferring to the bank account, please try again or contact help@vertopay.com ',
  [LANG_KEYS.LABEL_ENTER_CODE]: 'Enter Code',
  [LANG_KEYS.FORGOT_PASSWORD_EMAIL]: 'Check your email to reset the password',
  [LANG_KEYS.FORGOT_PASSWORD_SMS]: 'Reset password link sent!',
  [LANG_KEYS.FORGOT_PASSWORD_SMSINFO]:
    'A reset password link has been sent in a SMS text message to your phone.',
  [LANG_KEYS.RESET_PASSWORD]: 'Reset Password',
  [LANG_KEYS.RESET_PASSWORD_MEET_REQUIREMENTS]: 'Choose Password',
  [LANG_KEYS.RESET_PASSWORD_CONTINUE]: 'Continue',
  [LANG_KEYS.RESET_PASSWORD_MESSAGE]: 'Password reset successfully!',
  [LANG_KEYS.FINDPEOPLE_ADDRESS_BOOK_CONNECTED_TITLE]:
    'Address Book connected:',
  [LANG_KEYS.FINDPEOPLE_CONTACTS_NUMBER]:
    '{contacts} Contacts with phone numbers',
  [LANG_KEYS.FINDPEOPLE_SEARCH_BY]: 'Search by name or phone',
  [LANG_KEYS.FINDPEOPLE_TO_SEARCH_CONTACTS]: 'To search your contacts',
  [LANG_KEYS.FINDPEOPLE_CONNECT_ADDRESS_BOOK]: 'Connect your',
  [LANG_KEYS.PAYFORM_AMOUNT_LABEL]: 'Amount',
  [LANG_KEYS.PAYFORM_NOTE_LABEL]: 'For (required)',
  [LANG_KEYS.CLEAN_PAYFORM_NOTE_LABEL]: 'For',
  [LANG_KEYS.PAYLINK_AMOUNT_OPTIONAL_LABEL]: 'Pay What You Want',
  [LANG_KEYS.PAYLINK_NOTE_LABEL]: 'For {payee} (optional)',
  [LANG_KEYS.NEW_SIGNUP_TOKEN_CONFIRMATION]:
    'To confirm and protect your identity, a verification code was sent to {phone}. It might take a few minutes to get to you.',
  [LANG_KEYS.NEW_SIGNUP_TOKEN_ERROR_MESSAGE]: 'The code is incorrect.',
  [LANG_KEYS.NEW_SIGNUP_RESEND_TOKEN]: 'Try again or {link}.',
  [LANG_KEYS.NEW_SIGNUP_TOKEN_DIFFERENT_PHONE]:
    'Enter a different mobile number',
  [LANG_KEYS.NEW_SIGNUP_USERNAME_BUTTON_DISABLED]: 'Pick $VertoTag',
  [LANG_KEYS.NEW_SIGNUP_PASSWORD_BUTTON_ENABLED]: 'Start Using Verto',
  [LANG_KEYS.NEW_SIGNUP_PASSWORD_BUTTON_DISABLED]: 'Create Password',
  [LANG_KEYS.NEW_SIGNUP_TERMS]:
    'By clicking Start Using Verto, you agree to our {terms} and {privacy}, and our {usage}, and {pad} agreements',
  [LANG_KEYS.GETSTARTED_TITLE]: 'Get Started',
  [LANG_KEYS.REGISTER_TITLE]: 'Sign Up',
  [LANG_KEYS.PHONE_TITLE]: 'Your Mobile Number',
  [LANG_KEYS.PROFILE_MODAL_TITLE_NAME]: 'Edit Name',
  [LANG_KEYS.PROFILE_MODAL_TITLE_VERTOTAG]: 'Edit $VertoTag',
  [LANG_KEYS.PROFILE_MODAL_TITLE_EMAIL]: 'Edit Email',
  [LANG_KEYS.PROFILE_MODAL_TITLE_PHONE]: 'Edit Mobile Number',
  [LANG_KEYS.PROFILE_MODAL_TITLE_VERIFY]: 'Verify Mobile Number',
  [LANG_KEYS.PROFILE_MODAL_UPDATED_NAME]: 'Name Updated',
  [LANG_KEYS.PROFILE_MODAL_UPDATED_USERNAME]: '$VertoTag Updated',
  [LANG_KEYS.PROFILE_MODAL_UPDATED_EMAIL]: 'Email Verification sent',
  [LANG_KEYS.PROFILE_MODAL_UPDATED_EMAIL_VERIFIED]: 'Email Updated',
  [LANG_KEYS.PROFILE_MODAL_UPDATED_PHONE]: 'Mobile Number Updated',
  [LANG_KEYS.PROFILE_MODAL_UPDATED_VERIFY]: 'Mobile Number Updated',
  [LANG_KEYS.PROFILE_SAVE_BUTTON_VERIFY]: 'Save New Mobile Number',
  [LANG_KEYS.PROFILE_PHONE_CHANGE_TAKE_EFFECT]:
    'Mobile Phone Number update will take effect after successful verification.',
  [LANG_KEYS.PROFILE_EMAIL_CHANGE_TAKE_EFFECT]:
    'Email update will take effect after successful email address verification.',
  [LANG_KEYS.PROFILE_SAVE_CHANGES]: 'Save Changes',
  [LANG_KEYS.PROFILE_SEND_VERIFICATION_EMAIL]: 'Send Verification Email',
  [LANG_KEYS.FORM_ENTER_EMAIL_ADDRESS]: 'Enter your Email Address',
  [LANG_KEYS.ERROR_MESSAGE_01]:
    'Biometrics failed. Incorrect mobile number or password.',
  [LANG_KEYS.ERROR_MESSAGE_02]: 'Session expired.',
  [LANG_KEYS.QR_CODE_PAY_OR_REQUEST]: 'Scan the code to make a payment',
  [LANG_KEYS.SENT_TO_VERTO]: 'Transfer to Verto wallet',
  [LANG_KEYS.SENT_FROM_VERTO]: 'Transfer from Verto wallet',
  [LANG_KEYS.SENT_TO_VERTO_SNOOZED]:
    'Transfer to Verto wallet pending approval',
  [LANG_KEYS.SENT_FROM_VERTO_SNOOZED]:
    'Transfer from Verto wallet pending approval',
  [LANG_KEYS.ESTIMATED_ARRIVAL]: 'Estimated arrival 2-3 banking days',
  [LANG_KEYS.SCAN_CODE]: 'Scan Code',
  [LANG_KEYS.BILLSPLIT_MARK_AS_PAID]: 'Mark as Paid',
  [LANG_KEYS.BILLSPLIT_MARKED_AS_PAID]: 'Marked As Paid',
  [LANG_KEYS.PERMISSION_TITLE_FACE_ID]: 'Allow Verto to use Face ID',
  [LANG_KEYS.PERMISSION_TEXT_FACE_ID]:
    'Access your account with an added layer of ease and security.',
  [LANG_KEYS.PERMISSION_TITLE_REMINDER_FACE_ID]:
    'A second chance to allow Verto to use Face ID',
  [LANG_KEYS.PERMISSION_TEXT_REMINDER_FACE_ID]:
    'Access your account with an added laver of ease and security.',
  [LANG_KEYS.PERMISSION_TITLE_CONTACTS]: 'Find Your Contacts on Verto',
  [LANG_KEYS.PERMISSION_TEXT_CONTACTS]:
    'Verto stores contact data to enable finding your friends when you send or request payments.{brake}{brake}Read our <a>privacy policy</a> to learn about how we handle your data.',
  [LANG_KEYS.PERMISSION_TITLE_REMINDER_CONTACTS]:
    'A second chance to{brake}connect your Contacts',
  [LANG_KEYS.PERMISSION_TEXT_REMINDER_CONTACTS]:
    'Searching for friends with their name or mobile number is easier when you link and store your contacts. Verto stores contact data to enable finding your friends when you send or request payments{brake}{brake}Read our <a>privacy policy</a> to learn about how we handle your data.{brake}{brake}Just a heads up: Accepting this <i>might</i> restart the app.',
  [LANG_KEYS.PERMISSION_TITLE_CAMERA]: 'Allow Verto to access the Camera',
  [LANG_KEYS.PERMISSION_TEXT_CAMERA]:
    'Make it possible to scan payment QR codes add a display picture, and more.',
  [LANG_KEYS.PERMISSION_TITLE_REMINDER_CAMERA]:
    'A second chance to allow Verto to access the Camera',
  [LANG_KEYS.PERMISSION_TEXT_REMINDER_CAMERA]:
    'Make it possible to scan payment QR codes, add a display picture, and more.',
  [LANG_KEYS.PERMISSION_TITLE_GALLERY]:
    'Allow Verto to access your Photo Library',
  [LANG_KEYS.PERMISSION_TEXT_GALLERY]:
    "Select and use photos from your phone's photo library.",
  [LANG_KEYS.PERMISSION_TITLE_REMINDER_GALLERY]:
    'A second chance to allow Verto to access your Photo Library',
  [LANG_KEYS.PERMISSION_TEXT_REMINDER_GALLERY]:
    'Make it possible to add pictures.',
  [LANG_KEYS.PERMISSION_TITLE_NOTIFICATIONS]:
    'Allow Verto to send you Notifications',
  [LANG_KEYS.PERMISSION_TEXT_NOTIFICATIONS]:
    'Get notified for important activities with your account.',
  [LANG_KEYS.PERMISSION_TITLE_REMINDER_NOTIFICATIONS]:
    'A second chance to allow Verto to send you Notifications',
  [LANG_KEYS.PERMISSION_TEXT_REMINDER_NOTIFICATIONS]:
    'Get notified for important activities with your account.',
  [LANG_KEYS.ALERT_PREVIEW_ONLY]: 'Preview Only',
  [LANG_KEYS.SCHEDULE_PAGE_TITLE]: 'Schedules',
  [LANG_KEYS.ERROR_BILLSPLIT_INVALID_PHONE]:
    "Uh-oh! It looks like you've input an invalid user or phone number, please go back and try again",
  [LANG_KEYS.ACTIVITY_EXPIRED]: 'Expired {days} days ago.',
  [LANG_KEYS.ACTIVITY_EXPIRES]: 'Expires in {days} days.',
  [LANG_KEYS.ACTIVITY_TIME_FROM_ERROR_TRANSFERPAYMENT]: 'Error Payment',
  [LANG_KEYS.ACTIVITY_TIME_FROM_CANCELLED_TRANSFERPAYMENT]: 'Revoked Payment',
  [LANG_KEYS.ACTIVITY_TIME_FROM_EXPIRED_TRANSFERPAYMENT]: 'Expired Payment',
  [LANG_KEYS.ACTIVITY_TIME_FROM_DECLINED_TRANSFERPAYMENT]: 'Declined Payment',

  [LANG_KEYS.ACTIVITY_TIME_FROM_DECLINED_TRANSFERREQUEST]: 'Declined Request',
  [LANG_KEYS.ACTIVITY_TIME_FROM_CANCELLED_TRANSFERREQUEST]: 'Revoked Request',
  [LANG_KEYS.ACTIVITY_TIME_FROM_FAILED_TRANSFERREQUEST]: 'Failed Request',
  [LANG_KEYS.ACTIVITY_TIME_FROM_EXPIRED_TRANSFERREQUEST]: 'Expired Request',
  [LANG_KEYS.ACTIVITY_TIME_FROM_PENDING_TRANSFERREQUEST]: 'Pending Request',

  [LANG_KEYS.ACTIVITY_TIME_FROM_FAILED_TRANSFERIN]: 'Failed Transfer',
  [LANG_KEYS.ACTIVITY_TIME_FROM_DECLINED_TRANSFERIN]: 'Declined Transfer',
  [LANG_KEYS.ACTIVITY_TIME_FROM_SNOOZED_DECLINED_TRANSFERIN]:
    'Declined Snoozed Transfer',
  [LANG_KEYS.ACTIVITY_TIME_FROM_PENDING_TRANSFERIN]: 'Pending Transfer',
  [LANG_KEYS.ACTIVITY_TIME_FROM_QUEUED_TRANSFERIN]: 'Queued Transfer',
  [LANG_KEYS.ACTIVITY_TIME_FROM_PROCESSING_TRANSFERIN]: 'Processing Transfer',

  [LANG_KEYS.ACTIVITY_TIME_FROM_FAILED_TRANSFEROUT]: 'Failed Transfer',
  [LANG_KEYS.ACTIVITY_TIME_FROM_DECLINED_TRANSFEROUT]: 'Declined Transfer',
  [LANG_KEYS.ACTIVITY_TIME_FROM_SNOOZED_DECLINED_TRANSFEROUT]:
    'Declined Snoozed Transfer',
  [LANG_KEYS.ACTIVITY_TIME_FROM_PENDING_TRANSFEROUT]: 'Pending Transfer',
  [LANG_KEYS.ACTIVITY_TIME_FROM_QUEUED_TRANSFEROUT]: 'Queued Transfer',
  [LANG_KEYS.ACTIVITY_TIME_FROM_PROCESSING_TRANSFEROUT]: 'Processing Transfer',
};

export const getMessage = (key: string) => {
  return DEFAULT_MESSAGES[key];
};

const json = DEFAULT_MESSAGES;

export { json, LANG_KEYS, DEFAULT_MESSAGES };
