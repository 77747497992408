import { gql } from '@apollo/client';
export const PAYMENT_TEMPLATE_FRAGMENT = gql`
  fragment paymentTemplateFields on PaymentTemplate {
    ...paymentTemplateMinimalFields
    payWhatYouWant
    active
    paymentLink
    amount
    smsMessage
    createdAt
    updatedAt
    payee {
      ...userDetailFields
    }
    transfers {
      ...transferFields
    }
  }
`;
