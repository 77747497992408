import { REFRESH_TOKEN } from 'apollo/mutations/refreshToken';
import { cleanStorage } from 'hooks/useRedirections';
import jwt_decode from 'jwt-decode';
import { client, isLoggedIn, setCurrentUser } from './api';
import { sendWebWrapperMessage } from './utils';
import { cookies } from 'lib/ManageCookies';

const VERTO_DATA_STORE = 'VERTO_DATA_STORE';
const VERTO_TESTMODE = 'testmode';
const VERTO_USER_TOKEN = 'VERTO_USER_TOKEN';
const VERTO_USER_ID = 'VERTO_USER_ID';
const VERTO_USER_REFRESH_TOKEN = 'VERTO_USER_REFRESH_TOKEN';
const VERTO_FIND_PEOPLE = 'VERTO_FIND_PEOPLE';
const VERTO_CONTACTS = 'guestUser.contacts';
const VERTO_RAW_CONTACTS = 'guestUser.rawContacts';

// refresh token can be used up to 2 minutes since access token has expired
const REFRESH_TOKEN_EXPIRATION = 2 * 60;

const DEFAULT_DATA_STORE = {
  currentMode: 'livemode',
  sessionData: { livemode: {}, testmode: {} },
};

export const getTestmode = () => {
  if (window.location.pathname.startsWith('/checkout/cs_')) {
    // testmode on /checkout is determined only by the checkout session ID
    return window.location.pathname.startsWith('/checkout/cs_test_');
  }
  // everywhere else testmode is determined by the presence of the testmode flag in localStorage
  return Boolean(window.localStorage.getItem(VERTO_TESTMODE));
};

export const setTestmode = (testmode: boolean) => {
  if (testmode) {
    window.localStorage.setItem(VERTO_TESTMODE, 'true');
  } else {
    window.localStorage.removeItem(VERTO_TESTMODE);
  }
};

export const getCurrentMode = () => (getTestmode() ? 'testmode' : 'livemode');
const backCompatibleData = () => {
  const token = window.localStorage.getItem(VERTO_USER_TOKEN);
  const id = window.localStorage.getItem(VERTO_USER_ID);
  const refreshToken = window.localStorage.getItem(VERTO_USER_REFRESH_TOKEN);
  if (token && id) {
    return {
      ...DEFAULT_DATA_STORE,
      sessionData: {
        ...DEFAULT_DATA_STORE.sessionData,
        [getCurrentMode()]: {
          TOKEN: token,
          ID: id,
          REFRESH_TOKEN: refreshToken,
        },
      },
    };
  }
  return DEFAULT_DATA_STORE;
};

const current = () => {
  const currentValue = JSON.parse(
    window.localStorage.getItem(VERTO_DATA_STORE) as string
  );
  return currentValue || backCompatibleData();
};

const mobileBackCompatiple = () => ({
  TOKEN: backCompatibleData()?.sessionData?.[getCurrentMode()]?.['TOKEN'],
  ID: backCompatibleData()?.sessionData?.[getCurrentMode()]?.['ID'],
  REFRESH_TOKEN:
    backCompatibleData()?.sessionData?.[getCurrentMode()]?.['REFRESH_TOKEN'],
});

export const getToken = () =>
  current()?.sessionData?.[getCurrentMode()]?.['TOKEN'] ||
  mobileBackCompatiple()?.['TOKEN'];
export const getId = () =>
  current()?.sessionData?.[getCurrentMode()]?.['ID'] ||
  mobileBackCompatiple()?.['ID'];
export const getRefreshToken = () =>
  (current()?.sessionData?.[getCurrentMode()]?.['REFRESH_TOKEN'] ||
    mobileBackCompatiple()?.['REFRESH_TOKEN']) as string;
export const getDecodedToken = () => getToken() && jwt_decode(getToken());
export const isTokenExpired = () =>
  Boolean(getDecodedToken() && Date.now() >= getDecodedToken().exp * 1000);
export const getTokenExpiration = () => getDecodedToken().exp;
export const canRefreshSession = () => {
  return !(
    getDecodedToken() &&
    Date.now() >= (getDecodedToken().exp + REFRESH_TOKEN_EXPIRATION) * 1000
  );
};

export const getSessionExpirationTime = () => {
  if (!getToken()) return 0;
  return getTokenExpiration() * 1000 - Date.now();
};

export const getExpireDateFromToken = () => {
  const token = getDecodedToken();
  return token.exp;
};

export const isAuthed = () => Boolean(getToken() && !isTokenExpired());

export const setAuthTokens = (accessToken, user, refreshToken) => {
  const dataStore = {
    ...current(),
    currentMode: getCurrentMode(),
    sessionData: {
      ...current()?.sessionData,
      [getCurrentMode()]: {
        ID: user.auth0Id!,
        TOKEN: accessToken!,
        REFRESH_TOKEN: refreshToken!,
      },
    },
  };
  window.localStorage.removeItem(VERTO_CONTACTS);
  window.localStorage.removeItem(VERTO_RAW_CONTACTS);
  window.localStorage.setItem(VERTO_DATA_STORE, JSON.stringify(dataStore));
  // only set the raw tokens in livemode (they are used for the native app)
  if (!getTestmode()) {
    window.localStorage.setItem(VERTO_USER_TOKEN, accessToken!);
    window.localStorage.setItem(VERTO_USER_ID, user.auth0Id!);
    window.localStorage.setItem(VERTO_USER_REFRESH_TOKEN, refreshToken!);
  }
  cookies.setUuid(user!.uuid as string);
  cookies.setMaskedPhone(user!.phone as string);
  cookies.setPhone(user.phone?.toString() as string);
  setCurrentUser(user);
  isLoggedIn(isAuthed());
};

// delete the current session but leave any session from another mode
const clearCurrentSession = () => {
  const dataStore = {
    ...current(),
    currentMode: getCurrentMode(),
    sessionData: {
      ...current()?.sessionData,
      [getCurrentMode()]: {},
    },
  };
  window.localStorage.setItem(VERTO_DATA_STORE, JSON.stringify(dataStore));
};

export const clearTokens = () => {
  clearCurrentSession();
  window.localStorage.removeItem(VERTO_FIND_PEOPLE);
  window.localStorage.removeItem(VERTO_CONTACTS);
  window.localStorage.removeItem(VERTO_RAW_CONTACTS);
  window.localStorage.removeItem(VERTO_USER_TOKEN);
  window.localStorage.removeItem(VERTO_USER_ID);
  window.localStorage.removeItem(VERTO_USER_REFRESH_TOKEN);
  cleanStorage();
};

export const endSession = async (path: string | null) => {
  // FIXME: send a mutation to the API to invalidate this Auth0 access token
  // clear the localStorage items before the store to prevent any refetching from
  //  sneaking in and using the expired token
  clearTokens();
  // the customer app redirects (reloads) so we just clearStore
  return client.clearStore().then(() => {
    if (path) {
      sendWebWrapperMessage({ 'auth-pageLoaded': 'true' });
      return window.location.replace(path);
    }
  });
};
