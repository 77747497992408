import { gql } from '@apollo/client';
export const MEMBERSHIP_FRAGMENT = gql`
  fragment membershipFields on Membership {
    guid
    organization {
      ...organizationFields
    }
    roles
    permissions
  }
`;
