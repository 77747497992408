import React from 'react';
import Pusher from 'pusher-js';

const PusherContext = React.createContext<any>(null);
const pusherKey = process.env.REACT_APP_PUSHER_KEY;
const pusherCluster = process.env.REACT_APP_PUSHER_CLUSTER;

// Enable pusher logging - don't include this in production
Pusher.logToConsole = process.env.NODE_ENV !== 'production';

function PusherProvider({ children }) {
  const [pusher, setPusher] = React.useState<Pusher | null>(null);

  if (pusherKey && pusherCluster && pusher === null) {
    setPusher(new Pusher(pusherKey, { cluster: pusherCluster }));
  }

  return (
    <PusherContext.Provider value={{ pusher }}>
      {children}
    </PusherContext.Provider>
  );
}

// Create custom hook for using the Pusher Context
// Fail fast if not within a PusherProvider (thx Kent C. Dodds)
function usePusher() {
  const context = React.useContext(PusherContext);
  if (!context) {
    throw new Error('usePusher must be used within a PusherProvider');
  }

  const { pusher } = context;
  return pusher;
}

export { PusherProvider, usePusher };
