import React from 'react';

type Props = {
  children: React.ReactNode;
  size?: 'sm';
  centered?: boolean;
};

const PageContainer = ({ children, size, centered }: Props) => {
  const classNames = {
    sm: 'page-container--sm',
  };

  return (
    <div
      className={`page-container 
      ${size ? classNames[size] : ''} 
      ${centered ? 'page-container--centered' : ''}`}
    >
      {children}
    </div>
  );
};

export default PageContainer;
