import { gql } from '@apollo/client';
export const USER_DETAIL_FIELDS_FRAGMENT = gql`
  fragment userDetailFields on UserDetail {
    uuid
    username
    firstName
    lastName
    name
    phone
    profileImage
    status
    privacy
    type
  }
`;
