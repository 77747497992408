import { gql } from '@apollo/client';
import { PAYMENT_ITEM_FRAGMENT } from '../paymentItem/fields';
import { SPLIT_GROUP_USER_FRAGMENT } from '../splitGroupUser/fields';
import { USER_DETAIL_FIELDS_FRAGMENT } from '../user/details';

export const SPLIT_GROUP_LIST_FRAGMENT = gql`
  ${SPLIT_GROUP_USER_FRAGMENT}
  ${PAYMENT_ITEM_FRAGMENT}
  ${USER_DETAIL_FIELDS_FRAGMENT}
  fragment splitGroupListFields on SplitGroup {
    amount
    amountPaid
    description
    name
    createdAt
    status
    uuid
    splitGroupUsers {
      uuid
      amount
      manualAmount
      status
      user {
        ...userDetailFields
      }
    }
    user {
      name
      uuid
    }
  }
`;
