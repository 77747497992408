import React from 'react';
import { createRoot } from 'react-dom/client';

import { IntlProvider } from 'react-intl';
import { ApolloProvider } from '@apollo/client';
import { client } from './lib/api';

import './assets/scss/app.scss';
import { json } from './lang/en';
import * as serviceWorker from './serviceWorker';
import { PusherProvider } from 'context/PusherContext';
import App from 'components/App';
import { DittoProvider } from 'ditto-react';
import dittoData from './ditto';
import { Provider as RollbarProvider } from '@rollbar/react';

const rollbarConfig = {
  accessToken: '1b71c042fc6144ecb069ed61979e3570',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ENV || 'development',
  },
};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

const dittoVariants = ['base', 'french', 'portuguese'];

root.render(
  <RollbarProvider config={rollbarConfig}>
    <DittoProvider
      source={dittoData}
      variant={dittoVariants[0]}
    >
      <IntlProvider
        locale={'en'}
        messages={json}
      >
        <ApolloProvider client={client}>
          <PusherProvider>
            <App />
          </PusherProvider>
        </ApolloProvider>
      </IntlProvider>
    </DittoProvider>
  </RollbarProvider>
);
// root.render(
//   <CacheBuster
//     currentVersion={version}
//     isEnabled={isProduction} //If false, the library is disabled.
//     isVerboseMode={true} //If true, the library writes verbose logs to console.
//     loadingComponent={<></>} //If not pass, nothing appears at the time of new version check.
//   >
//     <IntlProvider
//       locale={'en'}
//       messages={json}
//     >
//       <ApolloProvider client={client}>
//         <PusherProvider>
//           <App />
//         </PusherProvider>
//       </ApolloProvider>
//     </IntlProvider>
//   </CacheBuster>
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
