import { gql } from '@apollo/client';
export const SPLIT_GROUP_USER_FRAGMENT = gql`
  fragment splitGroupUserFields on SplitGroupUser {
    uuid
    amount
    manualAmount
    status
    updatedAt
    createdAt
    user {
      uuid
    }
  }
`;
